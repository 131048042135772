import { useCallback, useState } from 'react';
import { WalletSource, AnyMangataError } from 'core';
import { useConnect } from 'wagmi';

export const useMetamaskAccount = () => {
  const [error, setError] = useState<string | null>(null);
  const { connectAsync, connectors } = useConnect();

  const metamaskConnector = connectors.find(
    (connector) => connector.name.toLowerCase() === WalletSource.Metamask,
  );

  const connectWallet = useCallback(async () => {
    if (!metamaskConnector) {
      return;
    }

    try {
      await connectAsync({
        connector: metamaskConnector,
      });
      setError(null);
    } catch (e) {
      const error = e as AnyMangataError;

      if (error?.code === -32002) {
        setError('Connect wallet request is already pending. Open Metamask to proceed.');
        return;
      }

      setError(error?.message);
    }
  }, [connectAsync, metamaskConnector]);

  return { connectWallet, error };
};
