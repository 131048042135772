import Decimal from 'decimal.js';
import { useState } from 'react';
import { AnyMangataError, QueryOptional, useRollupGasPriceQuery, useWeb3 } from 'core';
import { faucetAbi } from './faucetAbi';
import { useMutation } from '@tanstack/react-query';
import { useClient, useSendTransaction } from 'wagmi';
import { Hex } from 'viem';

const GASP_TOKEN_FAUCET_ADDRESS = '0xbEBe6a7CB330fA57b3d85F059068d7abB023E604';
export const GASP_TOKEN = {
  address: '0x5620cDb94BaAaD10c20483bd8705DA711b2Bc0a3',
  name: 'GASPV2',
  symbol: 'GASPV2',
  decimals: 18,
};

export const useFaucetContract = (
  chainId: QueryOptional<string>,
  userAddress: QueryOptional<string>,
) => {
  const web3s = useWeb3();
  const { sendTransactionAsync } = useSendTransaction();
  const client = useClient();
  const selectedWeb3 = (chainId && web3s?.[chainId]) || null;

  const [error, setError] = useState<string | null>(null);
  const { rollupGasPriceQuery } = useRollupGasPriceQuery(chainId);
  const gasPrice = rollupGasPriceQuery.data;

  const resolveError = (e: unknown) => {
    const error = e as AnyMangataError;

    if (error?.code === 4001) {
      setError('User denied transaction');
      return;
    }

    const causeStr = error?.cause
      ?.toString()
      ?.replace('_Eip838ExecutionError: execution reverted:', '');

    setError(causeStr || error?.message || error?.toString());
  };

  const claimTokens = async (faucetAddress: string) => {
    setError(null);

    if (!userAddress || !selectedWeb3) {
      return;
    }

    await selectedWeb3.eth.getBlockNumber();

    const contract = new selectedWeb3.eth.Contract(faucetAbi, faucetAddress);
    const method = contract.methods.requestTokens();

    const fee = await (async () => {
      if (!userAddress || !gasPrice) {
        return null;
      }

      const gasAmount = (await method.estimateGas({ from: userAddress })).toString() || '0';
      const totalInWei = new Decimal(gasAmount).mul(gasPrice).toString();

      return {
        gasAmount,
        ethAmount: selectedWeb3.utils.fromWei(totalInWei, 'ether'),
      };
    })();

    if (!fee || !gasPrice) {
      return;
    }

    await sendTransactionAsync({
      account: userAddress as Hex,
      to: faucetAddress as Hex,
      data: method.encodeABI() as Hex,
      gasPrice: BigInt(gasPrice),
      gas: BigInt(fee.gasAmount),
    });
  };

  const claimGaspMutation = useMutation(() => claimTokens(GASP_TOKEN_FAUCET_ADDRESS), {
    onError: resolveError,
  });

  const addGaspMutation = useMutation(
    async () => {
      setError(null);

      await client?.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: GASP_TOKEN,
        },
      });
    },
    {
      onError: resolveError,
    },
  );

  return {
    claimGaspMutation,
    addGaspMutation,
    error,
  };
};
